import React, { useState, useEffect, useContext, useCallback } from 'react';
import { CSVLink } from 'react-csv';

import * as UsersAction from 'store/actions/UsersActions';
import { store } from 'store';

import * as commonService from 'utils/CommonService';

// components
import UserTable from './UserList/UserList.js';
import Filter from './UserList/Filter';
import ApprovalModal from './UserList/approvalModal.js';

const UserList = () => {
  const defaultPagination = {
    page: 1,
    limit: 10,
    orderBy: 'DESC',
  };

  const globalState = useContext(store);
  const { usersDispatch } = globalState.dispatch;
  const { userList, status, count } = globalState.state.usersState;
  const [rowData, setRowData] = useState([]);
  const [pagination, setDefaultPagination] = useState(defaultPagination);
  const [filterParams, setFilterParams] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [isLoaded, setisLoaded] = useState(true);

  const getExportData = useCallback(() => {
    if (!loaded) {
      setLoaded(true);
      UsersAction.getExportedUsersData({
        body: { page: 1, limit: 10000000, orderBy: 'ASC' },
        dispatch: usersDispatch,
      }).then((res) =>
        setCsvData([
          ['User Name', 'Email', 'Approval Status', 'Profile Type'],
          ...res.users.map((user) => [
            user.user_name,
            user.email,
            user.approval_status || 'N/A',
            user.privacy_settings,
          ]),
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (isLoaded) {
      UsersAction.getUsers({ body: { ...pagination }, dispatch: usersDispatch });
      setisLoaded(false);
    }
  }, [rowData, userList, status, usersDispatch, pagination, getExportData]);

  useEffect(() => {
    if (JSON.stringify(rowData.length) !== JSON.stringify(userList.length) || status !== 'init') {
      const rows = userList;
      setRowData(rows);
    }
  }, [rowData, status, userList, filterParams]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getExportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const setPagination = (params) => {
    setDefaultPagination({ ...pagination, ...params });
    UsersAction.getUsers({
      body: { ...pagination, ...params, ...filterParams },
      dispatch: usersDispatch,
    });
  };

  const handleFilter = (e, filterForm) => {
    let filterObject;
    if (filterForm.email.includes('@')) {
      filterObject = { email: filterForm.email };
    } else {
      filterObject = { username: filterForm.email };
    }

    e.preventDefault();
    setDefaultPagination({ ...pagination, page: 1 });

    setFilterParams(filterObject);
    UsersAction.filterUsers({
      body: { ...pagination, page: 1, ...filterObject },
      dispatch: usersDispatch,
    });
  };

  const resetLeadFilterForm = (data) => {
    setFilterParams(data);
    setDefaultPagination({ page: 1, limit: 10 });
    UsersAction.getUsers({
      body: { page: 1, limit: 10 },
      dispatch: usersDispatch,
    });
  };

  const handleDelete = (user) => {
    commonService.isDialogOpen.onNext({
      open: true,
      data: {
        message: 'Are you sure?',
      },
      cancelText: 'Cancel',
      confirmText: 'Okay',
      onCancel: () => commonService.isDialogOpen.onNext(false),
      onConfirm: () => deleteUser(user),
    });
  };

  const deleteUser = (user) => {
    if (user)
      UsersAction.deleteUser({ id: user.id, dispatch: usersDispatch }).then((res) => {
        commonService.forSuccess('User deleted successfully!', 'Success');
      });
    commonService.isDialogOpen.onNext(false);
  };
  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4 text-right">
            {csvData.length ? (
              <CSVLink
                className="commonBtn mb-3 float-right"
                data={csvData}
                filename={'users-list.csv'}
              >
                Export CSV
              </CSVLink>
            ) : (
              <button className="commonBtn mb-3 float-right loading" disabled>
                Export CSV
              </button>
            )}
          </div>
        </div>
        <Filter resetLeadFilterForm={resetLeadFilterForm} handleFilter={handleFilter} />
        <UserTable
          totalCount={count}
          pagination={pagination}
          setPagination={setPagination}
          userList={rowData}
          usersDispatch={usersDispatch}
          UsersAction={UsersAction}
          handleDelete={handleDelete}
        ></UserTable>
      </div>
    </>
  );
};

export default UserList;
